import React from 'react'
import { Link } from 'gatsby'

import logo from 'images/logo.png'

const NotFoundPage = () => (
  <div className="l-404">
    <img src={logo} alt="Howell Farms Logo" />
    <h1>Page Not Found</h1>
    <p>The page you're looking for isn't here.</p>
    <Link to={'/'} className="buttonLink">Go to the homepage</Link>
  </div>
)

export default NotFoundPage
